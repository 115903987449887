import React, {Component} from "react";
import Wallpaper from "../../../components/Common/Layouts/wallpaper";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Connector from "../../../redux/common/Connector";
import {FetchDataFleetminder, UpcaseFirst} from "../../../api/fn";
import TableDataView from "../../Common/tables/TableDataView";
import GridItem from "../../Common/dashboard/Grid/GridItem";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import ControllerCalendar from "../../Common/ControllerInput/ControllerCalendarDateRange";
import GridContainer from "../../Common/dashboard/Grid/GridContainer";
import AssistantPhotoIcon from '@material-ui/icons/GolfCourse';



import {MyBoxSelecttion} from "../SetupAssociation/fn";
import {GetEntityData} from "./fn";
import {GetUserToken} from "../../../api/fetching-data";
import TableSimple from "../../Common/tables/TableSImple";
import ControllerButton from "../../Common/ControllerInput/ControllerButton";

const convertDateFormat_YYYYMMDD = (datesIn) => {
    return moment(datesIn).format("YYYY-MM-DD");
}

class FuelUsageDaily extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            vehicle: "",
            startDate: convertDateFormat_YYYYMMDD(new Date()),// new Date(),
            endDate: convertDateFormat_YYYYMMDD(new Date()),// new Date(),
            Usages: [],
            component: "list", // or detail
            selectedRecord: null,
        }

    }


    componentDidMount() {
        //todo


    }

    handleChangeStartDate = (date) => {
        this.setState({
            startDate: date
        });
    }
    handleChangeEndDate = (date) => {
        this.setState({
            endDate: date
        });
    }
    handleInput2 = (key, val) => {
        this.setState({
            [key]: val
        })
    }
    getHandleValue = (key) => {
        return this.state[key] || ""
    }
    onSubmit = async () => {
        console.log("State > ", this.state);
        let _this = this;
        _this.setState({
            Usages: [],
            isLoading: true
        })
        if (this.state.vehicle === "") {
           // return alert("Please select vehicle!")
        }

        let hub = {
            Org: GetUserToken().OrgCode,//"Test Demo" ,// GetUserToken().OrgCode,
            PeriodStart: this.state.startDate,
            PeriodEnd: this.state.endDate,
            vehicle: this.state.vehicle, // "CA 324 787" //this.state.vehicle
        }
        let endpoint = "/fuel/report/usage/daily-monthly";
        await FetchDataFleetminder(hub, endpoint, async (data) => {

            if (typeof data === "undefined") {
                _this.setState({
                    isLoading: false
                })
                return
            }
            console.log("onSubmit usage:", _this.state.Usages, data);
            if (data === null) {
                _this.setState({
                    isLoading: false
                })
                return
            }

            let out =[];
            for(let vehicle in data.Daily){
                if(_this.state.vehicle !== ""){
                    if(vehicle !==_this.state.vehicle){
                        continue
                    }
                }
                const rowsVehicle = data.Daily[vehicle];
                /*
                [
            /*{id: "Vehicle", label: "Reg.#"}
                {id: "DateIn", label: "Start", float: "left"},
                {id: "TotalCost", label: "Amount"},
                {id: "TotalLitersUsed", label: "Litters"},
                {id: "CostUnit", label: "Rands/Ltrs"},
                {id: "MileageIn", label: "Opening Mileage"},
                {id: "MileageOut", label: "Closing Mileage"},
                {id: "Distance", label: "Trip"},
                {id: "Actual", label: "Actual Rands/Km"},
                {id: "FlagPercentage", label: "Rating"},
                {id: "Target", label: "Target Rand/Km"},
                {id: "RandKm", label: "Spec Rand/Km"},
                {id: "Allowance", label: "Allowance"},
                {id: "TotalLitersUsed", label: "Litters"},
                {id:"Detail",label:"View"},
            ]
                 */
                for(let date in rowsVehicle){
                    const row = rowsVehicle[date];
                    let o={
                        "Org": hub.Org,
                        "Vehicle": row.Vehicle,
                        "DateIn": row.DateIn,
                        "DateOut": row.DateOut,
                        "QtyIn": 0,
                        "QtyOut": 0,
                        "MileageIn": row.OpenMileage,
                        "MileageOut": row.CloseMileage,
                        "Distance": row.Distance,
                        "Target": row.Target,
                        "Actual": row.Actual,
                        "Allowance": 3,
                        "FLagColor": row.FlagColor,
                        "FlagPercentage": 0,
                        "TotalCost": row.Expense,
                        "TotalLitersUsed": 0,
                        "Spec": 0,
                        "CostUnit": row.CostUnit
                    }
                    out.push(o)
                }


            }

            _this.setState({
                Usages: out,
                isLoading: false
            })


            //next({type: FUEL_DATA_RECEIVED, data: out});
        });
    }
    onSubmit2 = async () => {
        console.log("State > ", this.state);
        let _this = this;
        _this.setState({
            Usages: [],
            isLoading: true
        })

        /*
        hub.Org = "Test Demo"
	hub.Vehicle = "CA 324 787"
	hub.StartDate = "2019-06-01"
	hub.EndDate = "2019-12-31"
	*/
        if (this.state.vehicle === "") {
            return alert("Please select vehicle!")
        }

        let hub = {
            Org: GetUserToken().OrgCode,//"Test Demo" ,// GetUserToken().OrgCode,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            vehicle: this.state.vehicle, // "CA 324 787" //this.state.vehicle
        }
        let endpoint = "/fuel/usage/list/date-range";
        await FetchDataFleetminder(hub, endpoint, async (data) => {

            if (typeof data === "undefined") {
                _this.setState({
                    isLoading: false
                })
                return
            }
            console.log("onSubmit usage:", _this.state.Usages, data);
            if (data === null) {
                _this.setState({
                    isLoading: false
                })
                return
            }

            _this.setState({
                Usages: data,
                isLoading: false
            })


            //next({type: FUEL_DATA_RECEIVED, data: out});
        });
    }

    showDetailUsage = (row) => {
        this.setState({
            component: "detail", // or detail
            selectedRecord: row,
        });
    }
    closeShowDetailUsage = () => {
        this.setState({
            component: "list", // or detail
            selectedRecord: null,
        });
    }

    getColunms = () => {
        /*
        <tr>
                    <th>&nbsp;</th>
                    <th>Reg.#</th>
                    <th>Driver</th>
                    <th>Start</th>
                    <th>Cost</th>
                    <th>Fuel Spec</th>
                    <th>Rand/Km</th>
                    <th>Allowance</th>
                    <th>Target</th>
                    <th>&nbsp;Flag</th>
                    <th>Actual</th>
                    <th>Status</th>
                </tr>
         */
        return [
            /*{id: "Vehicle", label: "Reg.#"},*/
            {id: "DateOut", label: "Start", float: "left"},
            {id: "TotalCost", label: "Amount"},
            {id: "TotalLitersUsed", label: "Litters"},
            {id: "CostUnit", label: "Rands/Ltrs"},
            {id: "MileageIn", label: "Opening Mileage"},
            {id: "MileageOut", label: "Closing Mileage"},
            {id: "Distance", label: "Trip"},
            {id: "Actual", label: "Actual Rands/Km"},
            {id: "FlagPercentage", label: "Rating"},
            {id: "Target", label: "Target Rand/Km"},
            {id: "RandKm", label: "Spec Rand/Km"},
            {id: "Allowance", label: "Allowance"},
            {id: "TotalLitersUsed", label: "Litters"},
            {id:"Detail",label:"View"},
        ]
    }

    renderList = () => {

        if (this.state.component !== "list") {
            return
        }
        /*
        {
  "Org": "Test Demo",
  "Vehicle": "CA 324 787",
  "DateIn": "2019-06-01",
  "DateOut": "",
  "QtyIn": 68.68,
  "QtyOut": 0,
  "MileageIn": 92609,
  "MileageOut": 0,
  "Distance": 0,
  "Target": 0,
  "Actual": 0,
  "Allowance": 3.08,
  "FLagColor": "",
  "FlagPercentage": 0,
  "TotalCost": 0,
  "TotalLitersUsed": 0,
  "Spec": 22,
  "CostUnit": 11.920064
}
*/
        let self = this;
        const formatMe = (inData) => {
            let d = parseFloat(inData)
            return d.toFixed(2)
        }
        const makeColor = (colorIn) => {
            return (<span style={{color: colorIn}}>{colorIn}</span>)
        }
        const makeFlag = (colorIn) => {
            return (<AssistantPhotoIcon style={{color: colorIn}}/>)
        }
        const makeViewButton=(row)=>{
            return(
                <a style={{color:"blue",cursor:"pointer"}} onClick={()=>self.showDetailUsage(row)}>Detail</a>
            )
        }


        let ls = [];
        for (let i in this.state.Usages) {
            let row = this.state.Usages[i];
            row.CostUnit = formatMe(row.CostUnit);
            row.FlagPercentage = makeFlag(row.FLagColor);// formatMe(row.FlagPercentage);
            row.TotalCost = formatMe(row.TotalCost);
            row.FLagColor = makeColor(row.FLagColor);
            row.Detail =makeViewButton(row);
            row.Target =parseFloat( row.Target).toFixed(2);

            ls.push(row)
        }
        if (this.state.Usages.length === 0) {
            return null
        }
        let data = {columns: this.getColunms(), data: ls};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpcaseFirst("Vehicle Spec ")}
                    onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}
                />

            </GridItem>
        )

    }

    renderDetail = () => {

        if (this.state.component !== "detail") {
            return
        }

        /*
        o.FillingCurrent = row
		o.FillingNext = nextFilled
		*/
        const record = this.state.selectedRecord;
        const FillingCurrent = record.FillingCurrent;
        const FillingNext = record.FillingNext;

        let ls = [
            {key: "Open Date", val: FillingCurrent.Date},
            {key: "Close Date", val: FillingNext.Date},
            {key: "Open Mileage", val: FillingCurrent.Mileage},
            {key: "Close Mileage", val: FillingNext.Mileage},
            {key: "Open Litters", val: FillingCurrent.Quantity},
            {key: "Close Litters", val: FillingNext.Quantity},
            {key: "USed Litters", val: record.TotalLitersUsed},
            {key: "Travel Mileage", val: record.Distance},
            {key: "Travel Cost", val: "R " + record.TotalCost},
            {key: "Manufacture Spec Ltrs/100Km", val: record.Spec},
            {key: "Allowance", val: "R " + record.Allowance},
            {key: "Used Rand/Km by Spec", val: "R " + record.RandKm},
            {key: "Target cost Rand/Km", val: "R " + record.Target},
            {key: "Actual cost Rand/Km", val: "R " + record.Actual},
            {key: "Cost unit Rand/Km", val: "R " + FillingCurrent.CostUnit},
            {key: "Flag Color", val: record.FLagColor},
            {key: "Flag Percentage", val: record.FlagPercentage + "%"},
        ]

        return (
            <GridItem xs={12} sm={12} md={12}>
                <h3>Detail usage {this.state.vehicle} [{record.DateIn}]</h3>
                <TableSimple
                    data={ls}
                    includeHeader={false}
                />
                <ControllerButton
                    variant="outlined"
                    color="primary"
                    onClick={this.closeShowDetailUsage}
                />
            </GridItem>
        )

    }

    render() {
        return (
            <Wallpaper {...this.props} pageTitle={"Fuel Spec"} displayLeftPanel={true}>
                {/*<DatePicker
                    selected={this.state.startDate}
                    onChange={this.handleChangeStartDate}
                    className="form-control"
                />
                <DatePicker
                    selected={this.state.endDate}
                    onChange={this.handleChangeEndDate}
                    className="form-control"
                />*/}

                <GridContainer style={{marginTop: 50, paddingRight: 20, paddingBottom: 30, minWidth: "100%"}}>
                    <GridItem xs={12} sm={12} md={12}>
                        <h4 style={{marginLeft: 10, color: "#2196f3", fontSize: 16}}>
                            Fuel Usage
                        </h4>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{marginBottom: 10}}>
                        <MyBoxSelecttion
                            {...this.props}
                            label={"Vehicle"}
                            name={"vehicle"}
                            noImage={true}
                            ls={GetEntityData("vehicle", this.props)}
                            handleInput2={this.handleInput2}
                            getHandleValue={this.getHandleValue}
                            getValue={this.getHandleValue}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={12}>
                            <ControllerCalendar
                                label={"Start Date"}
                                label2={"End Date"}
                                handleInput2={this.handleInput2}
                                name={"startDate"}
                                getHandleValue={this.getHandleValue}
                                name2={"endDate"}
                                onSubmit={this.onSubmit}
                                btnLabel={"Search"}
                                variant={"contained"}
                                color={"primary"}
                            />
                        </GridItem>
                    </GridItem>
                    {this.renderList()}
                    {this.renderDetail()}
                </GridContainer>


                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )
    }

}


export default Connector(FuelUsageDaily);