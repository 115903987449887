import React, {Component} from "react";
import Wallpaper from "../../../components/Common/Layouts/wallpaper";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Connector from "../../../redux/common/Connector";
import {FetchDataFleetminder,getYTD} from "../../../api/fn";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import GridContainer from "../../../components/Common/dashboard/Grid/GridContainer";
import "../../../components/Pages/fuel/myStyle.css";

import {GetUserToken} from "../../../api/fetching-data";
import MyDash from "../../../components/Pages/fuel/DashExective";
import MyDash2 from "../../../components/Pages/fuel/DashExectiveIndex";
import MyDash3 from "../../../components/Pages/fuel/DashExectiveStatus";
import MyChartExective1 from "../../../components/Pages/fuel/ChartExective1";
import MyChartExective0 from "../../../components/Pages/fuel/ChartExective0";

import BoxSelect from "../../../components/Pages/fuel/ComboxFilterController";

import ControllerCalendar from "../../../components/Common/ControllerInput/ControllerCalendarDateRange";

const FloatFixed = (inNumber, dec) => {
    inNumber = parseFloat(inNumber);
    inNumber = inNumber.toFixed(2);
    return inNumber
};

const getDateYearDate=(nYear)=>{
    // Get the current date
    const currentDate = moment();
    // Subtract one year
    let num = parseInt(nYear)
    const oneYearAgo = currentDate.subtract(num, 'years');
    // Format the date if needed
    return oneYearAgo.format('YYYY-MM-DD');
}

class FuelExecutiveDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            vehicle: "CA 324 787",//"",
            startDate:getDateYearDate(1), //getYTD().startDate,// "2019-06-01", // convertDateFormat_YYYYMMDD(new Date()),// new Date(),
            endDate: getYTD().endDate,//"2019-12-31", // convertDateFormat_YYYYMMDD(new Date()),// new Date(),
            component: "all", // or month or day
            DataAll: [],
            selectedVehicle: "",
            selectedMonth: "",
            selectedDay: null,
            selectedGroupFilter: "all",
        }

    }

    componentDidMount = async () => {
        //todo
        await this.onSubmit();
        //this.lineChart();
    };

    handleGroupFilter = (val) => {
        this.setState({
            selectedGroupFilter: val
        })
    };
    handleChangeStartDate = (date) => {
        this.setState({
            startDate: date
        });
    };
    handleChangeEndDate = (date) => {
        this.setState({
            endDate: date
        });
    };
    handleInput2 = (key, val) => {
        this.setState({
            [key]: val
        })
    };
    getHandleValue = (key) => {
        return this.state[key] || ""
    };
    requestViewMonthly = (rowData) => {

        this.setState({
            component: "month",
            selectedVehicle: rowData.Vehicle,
        });
        console.log("View monthly request clicked22! ", rowData);
        //alert("View monthly request clicked! ");
    };
    closeViewMonthly = () => {
        this.setState({
            component: "all",
            selectedVehicle: "",
        });
    };
    onSubmit = async () => {
        console.log("State > ", this.state);
        let _this = this;
        _this.setState({
            DataAll: [],
            isLoading: true
        });
        if (this.state.vehicle === "") {
            //return alert("Please select vehicle!")
        }
        let hub = {
            Org: GetUserToken().OrgCode,//"Test Demo" ,// GetUserToken().OrgCode,
            PeriodStart: this.state.startDate,
            PeriodEnd: this.state.endDate,
            //vehicle: this.state.vehicle, // "CA 324 787" //this.state.vehicle
        };
        let endpoint = "/fuel/report/usage/daily-monthly";
        await FetchDataFleetminder(hub, endpoint, async (data) => {

            if (typeof data === "undefined") {
                _this.setState({
                    isLoading: false
                });
                return
            }
            console.log("onSubmit usage:", _this.state.Usages, data);
            if (data === null) {
                _this.setState({
                    isLoading: false
                });
                return
            }
            _this.setState({
                DataAll: data,
                isLoading: false
            })

        });
    };

    calculateTargetActual = (distance, cost, allowance, ltrKm, costUnit, radioUp, radioLower) => {
        let target, actual;
        let flagColor;

        //let find our Target
        if (cost !== 0 && distance !== 0) {
            actual = cost / distance;
            actual = FloatFixed(actual, 2)
        } else {
            actual = 0
        }

        //let find our Target
        target = ((ltrKm / 100) * costUnit) + allowance;
        target = FloatFixed(target, 2);

        //let find our flag
        flagColor = "none";
        if (actual >= radioUp) {
            flagColor = "red"
        } else if (actual < radioUp && actual >= radioLower) {
            flagColor = "orange"
        } else if (actual < radioLower) {
            flagColor = "green"
        }

        return {target, actual, flagColor};
    };
    getColomns = (idLabel) => {
        const myRightStyle = {
            cellStyle: {
                textAlign: "right",
            },
            headerStyle: {
                textAlign: "right"
            }
        };
        return [
            {id: "Vehicle", label: idLabel},
            {id: "Expense", label: "Fuel(Rand)", type: "currency", ...myRightStyle},
            {id: "Distance", label: "Mileage(Km)", ...myRightStyle},
            {id: "Actual", label: "Actual", ...myRightStyle},
            /*{id: "Target", label: "Target", ...myRightStyle},*/
            {id: "Rating", label: "Rating", ...myRightStyle},
            {id: "OpenMileage", label: "Km/Open", ...myRightStyle},
            {id: "CloseMileage", label: "Km/Close", ...myRightStyle}
        ]
    };

    findFlagColor = (actual, radioUp, radioLower) => {
        //let find our flag
        let flagColor = "none";
        if (actual >= radioUp) {
            flagColor = "red"
        } else if (actual < radioUp && actual >= radioLower) {
            flagColor = "orange"
        } else if (actual < radioLower) {
            flagColor = "green"
        }
        return flagColor
    }
    getAllFleetDataSummary = () => {
        let ls = [];
        let DataAll = this.state.DataAll.Monthly;
        /**
         * * let find our period
         */
        let periods = [];
        for (let vehicle in DataAll) {
            const rowDates = DataAll[vehicle];

            for (let date in rowDates) {
                periods.push(date)
            }
            break;
        }
        /**
         * let now found out what total by date
         */
        let data1 = [];
        let data2 = [];
        for (let indexDate in periods) {
            const targetDate = periods[indexDate];
            let totalMileage = 0, totalCost = 0;
            let costUnit = 0;

            for (let vehicle in DataAll) {
                if (typeof DataAll[vehicle][targetDate] !== "undefined") {
                    const row = DataAll[vehicle][targetDate];
                    totalMileage += row.Distance;
                    totalCost += row.Expense;
                    costUnit = row.CostUnit;
                }

            }
            data1.push({
                date: targetDate,
                mileage: totalMileage,
                expense: totalCost,
                costUnit: costUnit
            });
            data2.push([targetDate, totalMileage, totalCost]);


        }


        return {categories: periods, data1: data1, data2: data2};
    }
    getAllFleetDataSummaryMonth = (cMonth) => {
        let DataAll = this.state.DataAll.Monthly;
        /**
         * let now found out what total by date
         */

        let totalMileage = 0, totalCost = 0, costUnit = 0;

        for (let vehicle in DataAll) {
            if (typeof DataAll[vehicle][cMonth] !== "undefined") {
                const row = DataAll[vehicle][cMonth];
                totalMileage += row.Distance;
                totalCost += row.Expense;
                costUnit = row.CostUnit;
            }

        }
        let actual = 0;
        if (totalCost === 0) {
            actual = 0;
        } else {
            actual = totalCost / totalMileage;
            actual = actual.toFixed(2);
        }

        const results = {
            date: cMonth,
            mileage: totalMileage,
            expense: totalCost,
            costUnit: costUnit,
            actual: parseFloat(actual)
        }

        console.log("^^^^:> ", cMonth, " > ", results)
        return results
    }

    renderHeaderGlobalSummary = () => {
        const findActual = (expense, mileage) => {
            if (expense === 0) {
                return 0;
            }
            let actual = expense / mileage;
            return actual.toFixed(2)
        }
        /**
         * Let find total vehicle
         * @type {number}
         */
        let totalAll = 0, totalRed = 0, totalOrange = 0, totalGreen = 0;
        let totalMileageRed = 0, totalMileageGreen = 0, totalMileageOrange = 0;
        let totalExpenseRed = 0, totalExpenseGreen = 0, totalExpenseOrange = 0;
        let totExp = 0, totMil = 0;
        let data = this.state.DataAll.Fleet;
        for (let i in data) {
            const row = data[i];
            totalAll++;
            totExp += row.Expense;
            totMil += row.Distance;
            if (row.FlagColor === "red") {
                totalRed++;
                totalMileageRed += row.Distance;
                totalExpenseRed += row.Expense;
            } else if (row.FlagColor === "orange") {
                totalOrange++;
                totalMileageOrange += row.Distance;
                totalExpenseOrange += row.Expense;
            } else if (row.FlagColor === "green") {
                totalGreen++;
                totalMileageGreen += row.Distance;
                totalExpenseGreen += row.Expense;
            }
        }
        //colorIn, status, costPerKm, numberVehicle
        let ls = [
            {
                flagColor: "green",
                status: "Good",
                numberVehicle: totalGreen,
                actual: findActual(totalExpenseGreen, totalMileageGreen)
            },
            {
                flagColor: "orange",
                status: "Satisfactory",
                numberVehicle: totalOrange,
                actual: findActual(totalExpenseOrange, totalMileageOrange)
            },
            {
                flagColor: "red",
                status: "Bad",
                numberVehicle: totalRed,
                actual: findActual(totalExpenseRed, totalMileageRed)
            },
        ];

        const allExpense = totExp;// totalExpenseGreen + totalExpenseOrange +totalExpenseRed;
        const allMileage = totMil;// totalMileageGreen+totalMileageOrange+totalMileageRed;
        const allActual = findActual(allExpense, allMileage);
        const allFlagColor = this.findFlagColor(allActual, 8, 6)

        // let totalAll = 0, totalRed = 0, totalOrange = 0, totalGreen = 0;
        const getPercentageVehicle = (total, targetNumber) => {
            let per = (targetNumber / total) * 100;
            per = per.toFixed(1);
            return per;
        }
        let dataStatus = [
            {key: (<b>Total:</b>), val: (<b>{totalAll}</b>)},
            {key: "Good", val: getPercentageVehicle(totalAll, totalGreen) + "%"},
            {key: "Satisfactory", val: getPercentageVehicle(totalAll, totalOrange) + "%"},
            {key: "Bad", val: getPercentageVehicle(totalAll, totalRed) + "%"},
        ];

        let totalOps = 0.00, totalNonOps = 0.00;
        let totalService = allExpense;
        let dataStatusYTD = [
            {key: (<b>Total:</b>), val: (<b>R{allExpense.toFixed(2)}</b>)},
            {key: "Service", val: "R" + totalService.toFixed(2)},
            {key: "Ops", val: "R" + totalOps.toFixed(2)},
            {key: "Non-Ops", val: "R" + totalNonOps.toFixed(2)},
        ]

        return (
            <GridContainer style={{paddingRight: 0, paddingBottom: 0, minWidth: "102%", background: "white"}}>

                {/*<GridItem xs={12} sm={6} md={2}>
                    <p style={styleLabel}>Fleet Fuel Efficiency Index</p>
                </GridItem>*/}
                {/*<GridItem xs={12} sm={6} md={8}>
                    <p style={styleLabel}>Fleet Performance</p>
                </GridItem>*/}

                {/*<GridItem xs={12} sm={6} md={2}>
                    <p style={styleLabel}>YTD Fuel Spend</p>
                </GridItem>*/}

                <GridItem xs={12} sm={6} md={2} style={{
                    border:"5px solid lightgray",
                }}>
                    <p style={styleLabel}>Fleet Efficiency Index</p>
                    <MyDash2
                        colorIn={allFlagColor}
                        numberVehicle={1}
                        actual={allActual}
                        status={""}
                    />
                </GridItem>

                <GridItem  xs={12} sm={6} md={8} style={{
                    display: "flex",
                    flexDirection: "column",
                    border:"5px solid lightgray",
                }}>
                    <p style={{...styleLabel, minWidth: "100%"}}>Fleet Performance</p>
                    <div style={{minWidth: "100%",display:"flex",flexDirection:"row",minHeight:"100%"}}>
                        <GridItem xs={12} sm={6} md={4}>
                            <MyDash
                                colorIn={ls[0].flagColor}
                                numberVehicle={ls[0].numberVehicle}
                                actual={ls[0].actual}
                                status={ls[0].status}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                            <MyDash
                                colorIn={ls[1].flagColor}
                                numberVehicle={ls[1].numberVehicle}
                                actual={ls[1].actual}
                                status={ls[1].status}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                            <MyDash
                                colorIn={ls[2].flagColor}
                                numberVehicle={ls[2].numberVehicle}
                                actual={ls[2].actual}
                                status={ls[2].status}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={6} md={3}>
                            <MyDash3
                                borderColor={"lightgray"}
                                data={dataStatus}
                            />
                        </GridItem>
                    </div>


                </GridItem>
                <GridItem xs={12} sm={6} md={2} style={{
                    border:"5px solid lightgray",

                }}>
                    <p style={styleLabel}>YTD Fuel Spend</p>
                    <MyDash3
                        borderColor={"white"}
                        data={dataStatusYTD}
                    />
                </GridItem>

            </GridContainer>
        )

    };

    renderChartExective1 = () => {
        if (this.state.DataAll.length === 0) {
            return
        }
        const info = this.getAllFleetDataSummary();
        let fuelMileageAxeX1 = [], fuelMileageAxeX2 = [];
        let cData1 = [], cData2 = [], cData = [];
        let prices = [], expenses = [];
        for (let i in info.data1) {
            const row = info.data1[i];
            let mileage = parseInt(row.mileage);
            let expense = parseInt(row.expense);
            let price = parseFloat(row.costUnit);
            fuelMileageAxeX2.push([mileage]);
            fuelMileageAxeX1.push([expense]);
            expenses.push(expense);
            cData2.push([6]);
            let rating = row.expense / row.mileage;
            let my = rating;
            cData1.push(my);
            cData.push({date: row.date, actual: my});
            prices.push(price);
        }

        let actuals = [];
        let cats = [];
        let exps = [];
        for (let i in info.categories) {
            let date = info.categories[i];
            const row = this.getAllFleetDataSummaryMonth(date);
            cats.push(date);
            const arr = date.split("-");
            const dtt = moment(date + "-01").format("MMM-YY")
            actuals.push([dtt, row.actual])
            exps.push([dtt, row.mileage])
        }

        MyChartExective1({
            divId: "myChart1",
            periodFrom: "Jan-2019",
            periodTo: "Dec-2019",
            prices: prices,
            expenses: expenses,
            categories: info.categories
        });


        MyChartExective0({
            divId: "myChart2",
            categories: info.categories,
            data1: actuals,
            data2: actuals,
            axeX1: actuals,
            axeX2: fuelMileageAxeX2,
            labelX1: "Rand/Km",
            labelX2: "Mileage(Km)",
            maxSoft: 10
        });
        MyChartExective0({
            divId: "myChart3",
            categories: info.categories,
            data1: exps,
            data2: exps,
            axeX1: exps,
            axeX2: fuelMileageAxeX2,
            labelX1: "Mileage",
            labelX2: "",
            maxSoft: 40000
        });
        /*MyChartExective2({
            divId: "myChart3",
            categories: info.categories,
            data1: exps,
            data2: exps,
            axeX1: cData1,
            axeX2: cData2,
            labelX1: "Feet Mileage(Km)",
            labelX2: "Mileage",
            maxSoft: 18
        })*/
    };
    renderComboFilterLocation=()=>{
        let ls=[
            {key:"all",val:"All"},
            {key:"cape town",val:"Cape town"},
            {key:"jhb",val:"JHB"},
            {key:"durban",val:"Durban"},
        ];
        return(
            <BoxSelect
                options={ls}
                name={"selectedLocation"}
                handleInput2={this.handleInput2}
                label={"Vehicle Location"}
            />
        )
    }
    renderComboFilterVehicle=()=>{
        let ls=[
            {key:"all",val:"All"},
            {key:"CA 789 654",val:"CA 789 654"},
            {key:"CA 789 654",val:"CA 789 654"},
            {key:"CA 789 654",val:"CA 789 654"},
        ];
        return(
            <BoxSelect
                options={ls}
                name={"selectedVehicle"}
                handleInput2={this.handleInput2}
                label={"Vehicle Category"}
            />
        )
    }

    render() {
        return (
            <Wallpaper {...this.props} pageTitle={"Executive Dashboard"} displayLeftPanel={true}>
                <GridItem xs={12} sm={12} md={12}>
                    <h4 style={{marginLeft: 10, color: "#2196f3", fontSize: 24}}>
                        EXECUTIVE DASHBOARD
                    </h4>
                </GridItem>
                <div style={{
                    backgroundColor: "lightgray",
                    minWidth: "100%",
                    minHeight: "100%"
                }}>

                    <GridContainer style={{
                        backgroundColor: "white",
                        minWidth: "100%",
                        minHeight: 35,
                        maxHeight: 80,
                        display:"flex",
                        flexDirection:"row",
                        padding:10,
                        paddingBottom:40
                    }}>
                        <GridItem xs={12} sm={12} md={3}>
                            {this.renderComboFilterVehicle()}
                            <br/>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            {this.renderComboFilterLocation()}
                            <br/>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6} style={{
                            paddingTop:-10
                        }}>
                        <ControllerCalendar
                            label={"Start Date"}
                            label2={"End Date"}
                            handleInput2={this.handleInput2}
                            name={"startDate"}
                            getHandleValue={this.getHandleValue}
                            name2={"endDate"}
                            onSubmit={this.onSubmit}
                            btnLabel={"Search"}
                            variant={"contained"}
                            color={"primary"}
                        />
                        </GridItem>

                    </GridContainer>

                    <GridContainer style={{
                        margin: 20,
                        marginTop: 0,
                        padding: 40,
                        paddingTop: 10,
                        paddingBottom: 0,
                        minWidth: "95%",
                        backgroundColor: "lightgray",
                    }}>
                        {this.renderHeaderGlobalSummary()}
                    </GridContainer>
                    <GridContainer style={{
                        backgroundColor: "lightgray",
                        marginTop: 10,
                        paddingRight: 20,
                        paddingBottom: 30,
                        minWidth: "100%"
                    }}>
                        <GridItem xs={12} sm={12} md={6} style={{backgroundColor: "white", margin: 20}}>
                            <p style={styleLabel}>Total Fleet fuel spend: Jan 2013 - Dec 2013</p>
                            <div id={"myChart1"} style={{
                                minWidth: "95%",
                                maxWidth: "95%",
                                minHeight: 500,
                            }}>
                                &nbsp;
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={5} style={{
                            backgroundColor: "lightgray",
                            margin: 20,
                        }}>
                            <div style={{
                                backgroundColor: "white",
                                marginBottom: 20,
                                minWidth: "112%",
                                maxWidth: "112%",
                            }}>
                                <p style={styleLabel}>Fleet Fuel Efficiency (R/Km)</p>

                                <div id={"myChart2"} style={{
                                    minWidth: "100%",
                                    maxWidth: "100%",
                                    minHeight: 250,
                                }}>
                                </div>
                            </div>

                            <div style={{
                                backgroundColor: "white",
                                marginBottom: 0,
                                minWidth: "112%",
                                maxWidth: "112%",
                            }}>
                                <p style={styleLabel}>Fleet Mileage (Km)</p>
                                <div id={"myChart3"} style={{
                                    minWidth: "100%",
                                    maxWidth: "100%",
                                    minHeight: 250,
                                }}>

                                </div>
                            </div>
                        </GridItem>

                    </GridContainer>


                    {this.renderChartExective1()}


                    <LoadingProcessing open={this.state.isLoading}/>
                </div>
            </Wallpaper>
        )
    }

}


export default Connector(FuelExecutiveDashboard);

const styleLabel = {
    background: "white",//"#fc9e8a",
    color: "red",
    fontWeight: "bold",
    fontSize: 18,
    paddingLeft: 10,
};
