import {
    FUEL_LOAD_DATA,
    FUEL_DATA_RECEIVED, FUEL_SAVE_DATA, FUEL_DELETE_DATA,
} from "../constants";
import {GetUserToken} from "../../api/fetching-data";
import {FetchDataCrm, FetchDataFleetminder} from "../../api/fn";

const FuelDataMiddleware = store => next =>  async action => {
    console.log("FUEL_LOAD_DATA: FuelDataMiddleware :",action);
    if (action.type === FUEL_LOAD_DATA) {

        let hub = {
            Org: GetUserToken().OrgCode
        }
        let endpoint = "/fuel/data/loader";
        await FetchDataFleetminder(hub, endpoint, async (data) => {
            let out = {};
            if (typeof data !== "undefined") {
                out = data.List;
            }
            console.log("FFF2: FuelDataMiddleware:", out, data);
            next({type: FUEL_DATA_RECEIVED, data: out});
        });

    }else{
        next(action);
    }



}
export default FuelDataMiddleware;


const SaveDeleteFuelMiddleware = store => next => async action => {
    if (action.type === FUEL_SAVE_DATA) {
        let payload = action.payload;
        let module = action.module;
        let hub = payload;
        let endpoint = "/fuel/data/insert/" + module;
        await FetchDataFleetminder(hub, endpoint, async (data) => {
            let out = {};
            if (typeof data !== "undefined") {
                out = data.List;
            }
            console.log("SaveDeleteFuelMiddleware response > ",out);

            next({type: FUEL_DATA_RECEIVED, data: out});
        });
    }else if (action.type === FUEL_DELETE_DATA) {
        let payload = action.payload;
        let module = action.module;
        let hub = payload;
        let endpoint = "/fuel/data/remove/" + module;
        await FetchDataFleetminder(hub, endpoint, async (data) => {
            let out = {};
            if (typeof data !== "undefined") {
                out = data.List;
            }
            console.log("SaveDeleteFuelMiddleware response > ",out);

            next({type: FUEL_DATA_RECEIVED, data: out});
        });
    } else {
        next(action);
    }

}

export{
    SaveDeleteFuelMiddleware,
    FuelDataMiddleware
}