import {FetchDataCrm} from "../../api/fn";
import { FLEETMINDER_SAVE_DATA,FLEETMINDER_DELETE_DATA, FLEETMINDER_LOAD_RECEIVE } from "../constants";


const SaveMaintenanceMiddleware = store => next => async action => {
    if (action.type === FLEETMINDER_SAVE_DATA) {
        let payload = action.payload;
        let module = action.module;
        let hub = payload;
        let endpoint = "/maintenance/insert/" + module;
        await FetchDataCrm(hub, endpoint, async (data) => {
            let out = {};
            if (typeof data !== "undefined") {
                out = data.List;
            }
            console.log("SaveMaintenanceMiddleware response > ",out);

            next({type: FLEETMINDER_LOAD_RECEIVE, data: out});
        });
    }else if (action.type === FLEETMINDER_DELETE_DATA) {
        let payload = action.payload;
        let module = action.module;
        let hub = payload;
        let endpoint = "/maintenance/remove/" + module;
        await FetchDataCrm(hub, endpoint, async (data) => {
            let out = {};
            if (typeof data !== "undefined") {
                out = data.List;
            }
            console.log("SaveMaintenanceMiddleware response > ",out);

            next({type: FLEETMINDER_LOAD_RECEIVE, data: out});
        });
    } else {
        next(action);
    }

}

export default SaveMaintenanceMiddleware;
