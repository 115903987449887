import React from 'react';
import {Router, Route, Switch} from 'react-router';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import logger from 'redux-logger';
import rootReducer from './redux';
import store from "./redux/store";
import RequiredAuth from "./api/RequiredAuth"
import {EntityDataMiddleware} from "./redux/services/entity-data";

import HomeMaintenanceVerificationPage from "./pages/Maintenance/Settings/VerificationPage";
import LoginPage from "./pages/Common/LoginPage";
import NotFoundPage from "./pages/Common/404";
import SettingPage from "./pages/Maintenance/Settings/SettingPage";
import OperationMenuPage from "./pages/Maintenance/operations/OperationMenu";
import OperationEventPlanPage from "./pages/Maintenance/operations/EventPlanningPage";
import FuelFillingUp from "./pages/Fuel/logs/FuelFillUp";

import FuelUsageDaily from "./components/Pages/fuel/FuelUsageDaily";
import FuelUsageMonthly from "./components/Pages/fuel/FuelUsageMonthly";
import FuelFleetDashboard from "./pages/Fuel/dashboard/FuelFleetDashboard";
import FuelFleetOverview from "./pages/Fuel/dashboard/FuelFleetOverview";
import FuelUploadHistoryData from "./components/Pages/fuel/FuelUploadHistoryData";
import FuelExecutiveDashboard from "./pages/Fuel/dashboard/FuelExecutiveDashboard";
import FuelCategoryOverview from "./pages/Fuel/dashboard/FuelCategoryOVerview";
import FuelSettingConsumption from "./pages/Fuel/Settings/FuelSpec";
import FuelSettingEntities from "./pages/Fuel/Settings/FuelSettingEntities";
import FuelSettingUser from "./pages/Fuel/Settings/FuelSettingUser";
import FuelLogsQa from "./pages/Fuel/logs/FuelLogsQa";
import FuelLogsQaHistory from "./pages/Fuel/logs/FuellogsQaHistory";



const createHistory = require("history").createHashHistory;
const history = createHistory();


function App() {
    return (
        <Provider store={store}>
            <Router history={history}>
                <Switch>

                    <Route exact path="/maintenance-setting-verification"
                           component={RequiredAuth(HomeMaintenanceVerificationPage)}/>
                    <Route exact path="/login" component={LoginPage}/>
                    <Route exact path="/" component={LoginPage}/>
                    <Route exact path="/settings-entities" component={RequiredAuth(SettingPage)}/>

                    <Route exact path="/operations-menu" component={RequiredAuth(OperationMenuPage)}/>
                    <Route exact path="/operations-event-plan" component={RequiredAuth(OperationEventPlanPage)}/>




                    <Route exact path="/fuel-dashboard-executive" component={RequiredAuth(FuelExecutiveDashboard)}/>
                    <Route exact path="/fuel-fleet-overview" component={RequiredAuth(FuelFleetOverview)}/>
                    <Route exact path="/fuel-fleet-dashboard" component={RequiredAuth(FuelFleetDashboard)}/>
                    <Route exact path="/fuel-upload-history-data" component={RequiredAuth(FuelUploadHistoryData)}/>
                    <Route exact path="/fuel-filling" component={RequiredAuth(FuelFillingUp)}/>
                    <Route exact path="/fuel-logs-qa" component={RequiredAuth(FuelLogsQa)}/>
                    <Route exact path="/fuel-logs-qa-history" component={RequiredAuth(FuelLogsQaHistory)}/>
                    <Route exact path="/fuel-category-overview" component={RequiredAuth(FuelCategoryOverview)}/>
                    <Route exact path="/fuel-setting-consumption" component={RequiredAuth(FuelSettingConsumption)}/>
                    <Route exact path="/fuel-setting-entities" component={RequiredAuth(FuelSettingEntities)}/>
                    <Route exact path="/fuel-setting-user" component={RequiredAuth(FuelSettingUser)}/>


                    {/*<Route exact path="/fuel-consumption" component={RequiredAuth(ConsumptionSetup)}/>
                    <Route exact path="/fuel-usage-daily" component={RequiredAuth(FuelUsageDaily)}/>
                    <Route exact path="/fuel-usage-monthly" component={RequiredAuth(FuelUsageMonthly)}/>*/}


                    <Route exact path="*" component={NotFoundPage}/>
                </Switch>
            </Router>
        </Provider>
    );
}

export default App;
