
import eConfig from '../configures';
const TOKEN_KEY = "token";
const TOKEN_USER = "@vehicleminder-user";
const serverBackendGlobal = eConfig.ServerGlobal;

const PostReservation= async (hub, endpoint, callback) => {
    const server = eConfig["ServerFleetminder"];
    await PostTo(server,hub,endpoint,callback);
}

let PostTo = async (host,hub, endpoint, callback) => {
    const server = eConfig[host];
    let url = server + "" + endpoint;
    console.log("$$$$$----> ", url);
    const token = GetLoginToken();
    fetch(url, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(hub)
    })
        .then((response) => response.json())
        .then((response) => {
            //todo let check if the response code is 401 Unauthorized
            if(typeof response.error !=="undefined"){
                if( response.error ==="Unauthorised access to this resource"){
                    //alert("Unauthorised access to this resource, Please login!")
                    window.location.href = "#/login"
                }
            }
            callback(response, null);
        }).catch((e) => {
        callback(null, e, null);
    });
}



let PostToBackend = async (hub, backend, endpoint, callback) => {
    let url = serverBackendGlobal + "/" + backend + endpoint;
    console.log("$$$$$----> ", url);
    const token = GetLoginToken();
    fetch(url, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(hub)
    })
        .then((response) => response.json())
        .then((response) => {
            //todo let check if the response code is 401 Unauthorized
            if(typeof response.error !=="undefined"){
                if( response.error ==="Unauthorised access to this resource"){
                    alert("Unauthorised access to this resource, Please login!")
                    window.location.href = "#/login"
                }
            }

            callback(response, null);
        }).catch((e) => {
        callback(null, e, null);
    });
}


let SaveToken = (token, user) => {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(TOKEN_USER, JSON.stringify(user))
}
let RemoveToken = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(TOKEN_USER)
}
const GetUserToken = () => {
    return JSON.parse(localStorage.getItem(TOKEN_USER))
}
const GetLoginToken = () => {
    return localStorage.getItem(TOKEN_KEY)
}
let _retrieveData = async (key) => {
    const myKey = '@TRANSY:' + key;
    try {
        const value = await localStorage.getItem(myKey);
        if (value !== null) {
            return value;
        }
    } catch (error) {

        // Error retrieving data
        return ""
    }
}
let SaveToStorage = async (key, val) => {
    try {
        await localStorage.setItem('@VEHICLEMINDER:' + key, val);
    } catch (error) {
        // Error saving data
        console.log("_storeData > ", key, val);
    }
}
let _removeData = async (key) => {
    try {
        await localStorage.removeItem('@VEHICLEMINDER:' + key);
    } catch (error) {
        // Error saving data
        console.log("_removeData error > ", key);
    }
}


export {
    SaveToken,
    RemoveToken,
    GetUserToken,
    _retrieveData,
    SaveToStorage,
    _removeData,
    PostToBackend,
    PostTo,
    PostReservation
};

