import React, {Component, Fragment} from "react";
import Wallpaper from "../../../components/Common/Layouts/wallpaper";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Connector from "../../../redux/common/Connector";
import {FetchDataFleetminder} from "../../../api/fn";
import TableDataView from "../../Common/tables/TableDataView";
import GridItem from "../../Common/dashboard/Grid/GridItem";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import ControllerCalendar from "../../Common/ControllerInput/ControllerCalendarDateRange";
import GridContainer from "../../Common/dashboard/Grid/GridContainer";
import AssistantPhotoIcon from '@material-ui/icons/GolfCourse';


import {MyBoxSelecttion} from "../SetupAssociation/fn";
import {GetEntityData} from "./fn";
import {GetUserToken} from "../../../api/fetching-data";
import TableSimple from "../../Common/tables/TableSImple";
import ControllerButton from "../../Common/ControllerInput/ControllerButton";
import ChartMonthlyOverview3 from "./ChartMonthlyOverview3";
import {DropzoneDialog} from "material-ui-dropzone";

const convertDateFormat_YYYYMMDD = (datesIn) => {
    return moment(datesIn).format("YYYY-MM-DD");
}

class FuelUploadHistoryData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            open:false,
            Files:[],
            Outputs:[],
        }

    }


    componentDidMount() {
        //todo


    }

    saveToDatabase=async (file)=>{
        let _this = this;
        const reader = new FileReader();
        reader.onload =async () => {
            let files=_this.state.Outputs;
            const o={
                base64string: reader.result,
                filename:file.name,
            };
            await _this.onSubmit(o);
            files.push(o);
            _this.setState({Outputs:files})
            console.log(" saveToDatabase > ",o);

        }
        reader.readAsDataURL(file);
        reader.onerror = function (error) {
            console.log('Error uploading file: ', error);
        };
    }

    handleSave=async (files) =>{
        //Saving files to state for further use and closing Modal.
        let output =[];
        for(let i in files){
            await this.saveToDatabase(files[i]);
        }
        let data = {
            files: files,
        };

        this.setState({
            Files:files
        })

        console.log("Upload documents handleSave ",data);
        console.log("Upload documents handleSave 2 ",this.state.Outputs);



    }
    handleClose() {
        this.setState({
            open:false
        })
    }
    handleOpen() {
        this.setState({
            open:true
        })
    }
    onDropHandler=(files) =>{
        return
        const _this=this;
        let file = files;
        const reader = new FileReader();
        reader.onload = () => {
            let _files = _this.state.Files;
            _files.push({
                id:"",
                base64string: reader.result,
                filename:file.name,
            })
            _this.setState({
                Files:_files
            })
        };
        reader.readAsDataURL(file);
        reader.onerror = function (error) {
            console.log('Error uploading file: ', error);
        };
    }
    onSubmit = async (hub) => {
        console.log("State > ", this.state);
        let _this = this;
        _this.setState({
            isLoading: true
        })

        hub.Org = GetUserToken().OrgCode;
        hub.IsBase64 = false

        let endpoint = "/fuel/upload/historic/new";
        await FetchDataFleetminder(hub, endpoint, async (data) => {

            if (typeof data === "undefined") {
                _this.setState({
                    isLoading: false
                })
                return
            }
            console.log("onSubmit usage:", _this.state.Usages, data);
            if (data === null) {
                _this.setState({
                    isLoading: false
                })
                return
            }

            _this.setState({
                Usages: data,
                isLoading: false,
                open:false,
                Files:[],
            })
        });
    }





    render() {
        return (
            <Wallpaper {...this.props} pageTitle={"Fuel Spec"} displayLeftPanel={true}>

                <GridContainer style={{marginTop: 50, paddingRight: 20, paddingBottom: 30, minWidth: "100%"}}>
                    <GridItem xs={12} sm={12} md={12}>
                        <h4 style={{marginLeft: 10, color: "#2196f3", fontSize: 16}}>
                            Upload History Data
                        </h4>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{marginBottom: 10}}>
                        <DropzoneDialog
                            open={this.state.open}
                            onSave={this.handleSave.bind(this)}
                            acceptedFiles={[]}
                            showPreviews={true}
                            maxFileSize={5000000}
                            onClose={this.handleClose.bind(this)}
                            onDrop={this.onDropHandler}
                            filesLimit={this.props.filesLimit}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <h5>You can upload only csv file , and check properly your heading before upload</h5>
                        <button
                            onClick={()=>this.handleOpen()}
                        >Click here to upload</button>
                    </GridItem>

                </GridContainer>


                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )
    }

}


export default Connector(FuelUploadHistoryData);