import {SAVE_ATTIBUTE_PEOPLE, SAVE_ROLE, SAVE_UTIL,LOAD_TICKET_DATA,TICKET_DATA_RECEIVED} from "../constants";


const initial = {
    Roles: [
        /*{name: "support", description: "Support"},
        {name: "admin", description: "Admin"},
        {name: "customer", description: "Customer"},*/
    ],
    ChartRole: [],
    Actions: [],
    Attributes:[],
    Databases: [],
    /*Step*/
    Categories: [],
    Priorities: [],
    Stages: [],
    Status: [],
    /* Services */
    Services: [],
    ServiceModel: [],
    ServiceChart: [],
    ServiceDetail: [],
    ServiceCost: [],
    ServiceCategory: [],
    ServiceFAQ: [],
    Solutions: [],
    Requests: [
        {
            id: 135,
            subject: "Test 01",
            author: "Djane Nongo",
            assignto: "Unssigned",
            dueby: "---",
            status: "Open",
            created: "Dec 2,2019 03:07",
            service: "Pensure",
            site: "---",
            behalf: "---"
        },
        {
            id: 134,
            subject: "Please provide the service for our new client",
            author: "Kuminga",
            assignto: "Cathrine",
            dueby: "Nov 17,2016 11:12",
            status: "Open",
            created: "Dec 16,2019 03:07",
            service: "Pensure",
            site: "JHB",
            behalf: "---"
        },
        {
            id: 18,
            subject: "Unable to fetch mails",
            author: "Guest",
            assignto: "Unssigned",
            dueby: "---",
            status: "Open",
            created: "Dec 2,2019 03:07",
            service: "Easicare",
            site: "---",
            behalf: "---"
        },
    ],
    Files:[],
    Filters:[],
    Utils:[],
    Associations:[],
};


const enquiry = (state = initial, action) => {
    let newState = state;
    switch (action.type) {
        case TICKET_DATA_RECEIVED:
            console.log("REQ-2: TICKET_DATA_RECEIVED :",action);
            const data=action.data;
            if(typeof data ==="undefined"){
                return Object.assign({}, newState);
            }
            newState.Roles=data.Roles;
            newState.Attributes =data.Attributes;
            newState.Databases =data.Databases;
            newState.Files = data.Files;
            newState.Filters = data.Solutions;
            newState.Utils =data.Util;
            newState.Associations = data.Associations;
            return Object.assign({}, newState);
        case SAVE_ROLE:
            newState.Roles = action.payload;
            return Object.assign({}, newState);
        case SAVE_ATTIBUTE_PEOPLE:
            newState.AttributePeople = action.payload;
            return Object.assign({}, newState);
        case SAVE_UTIL:
            newState.Roles = action.payload;
            return Object.assign({}, newState);
        default:
            return state
    }
}





export default enquiry;




