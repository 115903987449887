import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from "@material-ui/core/IconButton";
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import PersonIcon from '@material-ui/icons/Person';
import ProfileImage from "./ProfileImage";
import {UpcaseFirst} from "../../../api/fn";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '33.33%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
}));

export default (props) => {
    let {onDeleteRecord, associations,state}=props;
    const classes = useStyles();

    console.log("ZZZZZZZ_----> ", associations);

    let ls =[];
    for(let i in associations){
        const row=associations[i];
        if(state.MainEntity !==row.mainentity){
            continue
        }
        if(state.SupplierEntry !==row.supplierentity){
            continue
        }
        ls.push(row)
    }



    const stylesInner={
        alignItems: "center", justifyContent: "center",fontSize:12
    }
    const styleTitle={
        fontSize:14,
        fontWeight:"bold"
    }

    return (
        <div className={classes.root}>

            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>&nbsp;</TableCell>

                            <TableCell style={stylesInner}>
                                <IconButton edge="end" aria-label="delete">
                                    {/*<PersonIcon style={{color: "blue", fontSize: 35,}}/>*/}
                                    <span style={styleTitle}>{UpcaseFirst(state.MainEntity)}</span>
                                </IconButton>
                            </TableCell>
                            <TableCell align="left">
                                <IconButton edge="end" aria-label="delete">
                                    {/*<LocalTaxiIcon style={{color: "blue", fontSize: 35,}}/>*/}
                                    <span style={styleTitle}>{UpcaseFirst(state.SupplierEntry)}</span>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ls.map((row) => {

                            return (
                                <TableRow key={row.name}>

                                    <TableCell component="th" scope="row" width={35} >
                                        <IconButton edge="end" aria-label="delete"
                                                    onClick={() => onDeleteRecord(row)}>
                                            <DeleteIcon style={{color: "red"}}/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell component="td" scope="row" style={stylesInner}>
                                        <ProfileImage
                                            bgColor="blue"
                                            formatVariant={"circle"}
                                            link={row.mainimg}
                                            title={row.mainname}
                                        />&nbsp;&nbsp;
                                    </TableCell>
                                    <TableCell component="td" align="right" style={{textAlign:"right"}}>
                                        <ProfileImage
                                            bgColor="red"
                                            link={row.supplierimg}
                                            title={row.suppliername}
                                        />
                                        {/*<ProfileImage
                                            bgColor="red"
                                            alignContent={"right"}
                                            formatVariant={"square"}
                                            link={row.supplierimg}
                                            title={row.suppliername}
                                        />*/}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

