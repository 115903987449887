/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';



export default function ComboBox(props) {
    const {options,label,handleInput2,name}=props;

    const useStyles = makeStyles(theme => ({
        root: {
            width: "100%",
            minWidth: "100%",
            maxWidth: "100%",
            minHeight:"100%",
            maxHeight:"100%",
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
        inputRoot:{
            color:"lightblack",
            fontSize: 18,
        }
    }));
    const classes = useStyles();
    //let options=[];
    let indexSelected=0;
    let x=0;



    return (
        <Autocomplete
            classes={classes}
            size="small"
            id={name}
            options={options}
            defaultValue={options[indexSelected]}
            getOptionLabel={option => option.val}
            style={{ minWidth: "90%",maxWidth:"90%",minHeight:"90%" ,maxHeight:"75%",}}
            onChange={(e,val)=>{
                console.log("RTTTT--> ",val.key)
                handleInput2(name,val.key)
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    variant={"outlined" /*standard*/}
                    fullWidth
                    placeholder={"Select "+label}
                    style={{
                        color:"orange",
                        fontWeight:"bold",
                        fontSize:20
                    }}
                />
            )}
        />
    );
}
