import {LOAD_DATA, TICKET_DATA_RECEIVED} from "../constants";
import {GetUserToken} from "../../api/fetching-data";
import {FetchDataCrm} from "../../api/fn";

const EntityDataMiddleware = store => next =>  async action => {
    if (action.type === LOAD_DATA) {
            let hub = {
                Org: GetUserToken().OrgCode
            }
            let endpoint = "/entity/loader";
            await FetchDataCrm(hub, endpoint, async (data) => {
                let out = {};
                if (typeof data !== "undefined") {
                    out = data.List;
                }
                console.log("FFF2: LOAD_DATA:", out, data);
                next({type: TICKET_DATA_RECEIVED, data: out});
            });

    }else{
        next(action);
    }



}


export default EntityDataMiddleware;

