import React, {Fragment} from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export default (props) => {
    let {counterRed, counterAll, counterOrange, counterGreen, handleSelectGroup} = props;
    const [value, setValue] = React.useState('all');

    const handleChange = event => {
        setValue(event.target.value);
        handleSelectGroup(event.target.value);
    };

    const MyButtom = ({label,color, counter}) => {
        return (
            <div style={{display: "flex", flexDirection: "row"}}>
                <div style={{fontWeight:"bold",minWidth: 100,maxWidth: 100,fontSize: 24, color: color}}>&nbsp;{label}</div>

                &nbsp;&nbsp;<span style={{fontWeight:"bold",color: color, fontSize: 24}}>{counter}</span>
                &nbsp;&nbsp;<span style={{fontWeight:"bold",fontSize: 24}}>|</span>
            </div>
        )
    }

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">Fleet Index overview</FormLabel>
            <RadioGroup row aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                <FormControlLabel
                    value="all"
                    control={<Radio/>}
                    label={<MyButtom  label={"Total"} color={"black"} counter={counterAll}/>}
                />
                <FormControlLabel
                    value="green"
                    control={<Radio/>}
                    label={<MyButtom label={"Low"} color={"green"} counter={counterGreen}/>}
                />
                <FormControlLabel
                    value="orange"
                    control={<Radio/>}
                    label={<MyButtom label={"Meduim"} color={"orange"} counter={counterOrange}/>}
                />
                <FormControlLabel
                    value="red"
                    control={<Radio/>}
                    label={<MyButtom label={"Critical"} color={"red"} counter={counterRed}/>}
                />


            </RadioGroup>
        </FormControl>
    );
}
