import React,{Fragment} from 'react';
import moment from "moment";
import SignInSide from "../../components/Pages/Login/SignIn";
import Connector  from "../../redux/common/Connector";
import {cleanInputUsername} from "../../api/fn";
import {
    PostTo,
    SaveToken,
} from "../../api/fetching-data";

import {SERVER_RESERVATION,SERVER_VEHICLEMINDER,SERVER_FLEETMINDER} from "../../configures/constants";

class LoginPage extends React.Component{
    constructor(props){
        super(props);
        this.state={
            email:"",
            password:""
        }
    }
    handleInput=(e)=>{
        console.log("=> ",e.target.name," > ",e.target.value);
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    submitLogin=async (e)=>{
        e.preventDefault();
        const _this =this;
        let hub = this.state;
        if(typeof this.state.email ==="undefined" || typeof this.state.password ==="undefined"){
            alert("Error Username or password is empty!");
            return
        }
        if(this.state.email ==="" ||  this.state.password ===""){
            alert("Error Username or password is empty!");
            return
        }
        hub.username = cleanInputUsername(this.state.email);
        hub.password = typeof  this.state.password ==="undefined"?"":this.state.password.trim();

        let endpoint = "/user/login";

        console.log("submitLogin > ", hub, endpoint);

        await PostTo(SERVER_FLEETMINDER,hub, endpoint, function (data) {

            console.log("):(--->Login response: ",data)

            if(data ===null){
                //alert("Server connection Error! try again later");
                return
            }

            const res = data;
            console.log("submitForm response > ", data);
            if (!res.boo) {
                alert("Login Fail please try again! "+res.msg);
                return
            }

            SaveToken(res.token,res.user);
            _this.props.SaveLoginInfo({
                Token: res.token,
                User: res.user,
                Date: moment().format("DD MMM YYYY"),
                Time: moment().format("HH:MM:s")
            });
            window.location.href = "#/fuel-dashboard-executive"

        });


    }

    render(){

        return (
            <Fragment>
                <SignInSide
                    state={this.state}
                    handleInput={this.handleInput}
                    onSubmit={this.submitLogin}
                />
            </Fragment>
        );
    }
}

export default Connector(LoginPage);

